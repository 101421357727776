const danish = () => {
    return {
        arias: {
            cover: "Forsidenummer",
            textArea: "Bogtekst, side",
            choose_page_image: "Vælg sidebillede"
        },
        page: "Side",
        reset: "Nulstil",
        reset_dis: "Redigering vil være tilgængelig efter køb",
        finish: "Afslut",
        finished: {
            book: {
                one: "Cool, nu er det vores tur til at udføre magien",
                second: "{{name}}'s bog vil ",
                third: "ankomme om {{days_num}} arbejdsdage til:"
            },
            mebook_info: {
                one: "Tøv ikke med at kontakte os, når det passer dig -"
            },
            pdf: {
                one: "Ser fantastisk ud",
                second: "Din bog vil blive sendt til",
                third: "om få minutter",
                fourth: "Vi håber, du nyder den :)"
            },
            to_watch_status: "For at se status på din bog, gå til",
            status_title: "Status"
        },
        general_generating_book_error: {
            error: "Vi har en dårlig dag, der skete en fejl, vi er ved at rette den"
        },
        already_finished: {
            first: "Denne bog er allerede afsluttet",
            second: ""
        },
        returned_book: {
            first: "Denne bog er annulleret"
        },
        main_title: "Rediger din bog for at afslutte",
        choose_cover: "Vælg historieforside",
        errorCode: {},
        choose: "Vælg",
        choose_hard_cover: "Køb Hardcover",
        complete_subtitle: "Efter betaling kan du redigere historien og sende den til print",
        complete_subtitle_2: "Rabatterne opdateres på checkout-siden",
        choose_e_book: "Køb E-bog",
        save: "Gem",
        to_buy: "Fuldfør din bestilling",
        preview_and_make_pdf: "Forhåndsvis og send bog",
        preview_and_make_book: "Forhåndsvis og print bog",
        tutorial_title: "Vejledning",
        print: "Print",
        tutorial_items: [
            "Vælg venligst et billede til hver side",
            "Du kan redigere tekst på siden",
            "Du kan gemme og redigere senere",
            "Du kan redigere forsiden af siden",
            "For at afslutte skal du trykke på Forhåndsvis og send bog"
        ],
        end_process: {
            main_text: "Fantastisk! Vælg en af mulighederne for din bogs forside",
            buttonText: "Til betaling",
            choose_cover_pdf: "Øjeblikkelig digital magi",
            choose_cover_hard: "Magisk hardcover bog",
            p_description_pdf:
                "En høj-kvalitets digital e-bog i PDF-format, smukt designet og læsbar hvor som helst og når som helst.",
            p_description_hard: "Trykt bog med hardcover og høj-kvalitets print, med et moderne design",
            price_pdf: "",
            price_book: "+ levering",
            free_delivery: "Gratis levering",
            price_book_sub: ""
        },
        loading_texts: {
            text1: "Sprudlende fantasi...",
            subtext1: "Kun få magiske øjeblikke tilbage!",
            text2: "Udløsning af kreativitet...",
            subtext2: "Din personlige eventyr er undervejs!",
            text3: "Omfavn forventningen...",
            subtext3: "Din AI-bog er under udarbejdelse!",
            text4: "Fordyb dig i undren...",
            subtext4: "Dit personlige eventyr er undervejs!",
            text5: "Fængslende øjeblikke venter...",
            subtext5: "Vi skaber en historie kun til dig!"
        },
        tutorial_checkbox: "Vis ikke igen",
        terms: "accepterer webstedets ",
        terms_part_2: "brugsbetingelser",
        terms_link: "https://mebook.ai/en/refund_returns/",
        click_me: "Forhåndsvis historie",
        tutorial_button_text: "Start",
        terms_modal_h:
            "Denne aftale beskriver vilkårene og betingelserne for køb foretaget via minbørnebog hjemmeside og mobilapplikationer. Den giver også oplysninger om vores returnerings- og refusionspolitik.",
        terms_modal: [
            {
                title: "Bestillinger",
                p: "Du kan bestille personlige børnebøger gennem vores hjemmeside eller mobilapps. Alle ordrer kræver indsendelse af oplysninger om dit barn samt betalingsoplysninger. Ved at afgive en ordre bekræfter du, at alle oplysninger er korrekte."
            },
            {
                title: "Prissætning og betaling",
                p: "De viste priser inkluderer alle gældende skatter og afgifter. Betaling skal ske fuldt ud på købstidspunktet via kreditkort eller en anden betalingsmulighed. Minbørnebog forbeholder sig retten til at opdatere priser til enhver tid."
            },
            {
                title: "Ordrebekræftelse og behandling",
                p: "Du vil modtage en ordrebekræftelse via e-mail med detaljer om dit køb. Bogfremstilling og trykning begynder, når betalingen er blevet behandlet. Estimerede leveringstider vil blive kommunikeret i ordrebekræftelsen."
            },
            {
                title: "Fragt og levering",
                p: "Vi sender din færdige bog til den adresse, der blev specificeret ved checkout. Leveringstider kan variere afhængigt af din placering. Minbørnebog kan ikke holdes ansvarlig for forsinkelser forårsaget af tredjeparter."
            },
            {
                title: "Returneringer og refunderinger",
                p:
                    "Hvis din bog er beskadiget under fragten eller adskiller sig væsentligt fra forhåndsvisningen, bedes du kontakte os inden for 7 dage efter levering for at starte en returnering. Vi vil vurdere den indsendte klage og kan anmode om fotobevis." +
                    "Hvis godkendt, refunderer vi det fulde købsbeløb, når bogen er returneret til vores lager. Returfragtomkostninger vil blive refunderet, hvis returneringen skyldes skade eller fejl fra vores side."
            },
            {
                title: "Annullering eller ændring af ordrer",
                p: "På grund af de personlige karakter af vores bøger kan ordrer ikke annulleres eller ændres, når bogfremstillingen er startet. Gennemgå venligst ordrens detaljer grundigt, før du afgiver din bestilling."
            },
            {
                title: "Ansvarsbegrænsning",
                p: "Minbørnebog er ikke ansvarlig for nogen indirekte, hændelige eller følgeskader, der opstår som følge af køb eller brug af vores produkter. Vores maksimale ansvar er begrænset til købsprisen på bogen."
            },
            {
                title: "Kontakt os",
                p: "Hvis du har spørgsmål eller bekymringer vedrørende din ordre, bedes du kontakte os på support@minboernebog.dk. Vi er dedikerede til en sjov og nem bestillingsoplevelse og din fulde tilfredshed."
            }
        ],
        tutorial_modal: {
            cov: "Vælg historieforside",
            items: "Vælg dit yndlingsbillede til hver side",
            texts: "Du kan redigere teksten for hver side",
            reset: "Du kan nulstille teksten til originalen",
            save: "Du kan gemme bogen til senere redigering",
            finish: "For at afslutte bogen skal du trykke på afslut-knappen",
            skip: "Spring over",
            next: "Næste",
            back: "Tilbage",
            last: "Sidste"
        },
        tutorial_modal_preview: {
            cov: "Vælg venligst forside",
            items: "Vælg venligst billede til hver side",
            texts: "Du kan redigere teksten for hver side",
            reset: "Du kan nulstille teksten til originalen",
            save: "Du kan gemme bogen til senere redigering",
            finish: "For at afslutte bogen skal du trykke på afslut-knappen"
        },
        preview: {
            image_blurred_text: "Billedet vil blive vist efter køb"
        },
        back_to_website: "Tilbage til webstedet",
        more_images_editor: "Ikke helt rigtig?",
        more_images_editor_2: "Generér flere!",
        more_images_editor_warn:
            'Vi er dedikerede til at gøre din bog helt speciel. Hvis de genererede billeder ikke er helt, hvad du havde i tankerne, kan du generere et nyt sæt! Klik blot på "Generer igen" og lad vores AI gøre sin magi igen.\n' +
            "\n" +
            "Vær opmærksom på, at du for at sikre en glat og effektiv oplevelse kan regenerere billeder til op til 10 sider pr. bog.",
        more_images_editor_warn_left: "Resterende",
        more_images_editor_warn_left_1: "forsøg",
        changes_saved: "Ændringer gemt",
        more_images_button_warn: "Bekræft",
        more_data_prepearing: "Skaber et magisk billede",
        past_books: "Tidligere bøger",
        after_book_sent: {
            title: "Efter at bogen er sendt til produktion:",
            text1: "Bemærk venligst, at når bogen er produceret, kan der ikke foretages ændringer.",
            text2: "Sørg for, at barnets navn er",
            text3: "og at der ikke er stavefejl i historien, hvis du har foretaget ændringer i den.",
            text4: "Sørg også for, at de markerede billeder er dem, du bedst kunne lide af de mulige fire.",
            button: "Bekræft",
            edited_cover:
                "Systemet har registreret, at du har ændret bogens titel. Bekræft venligst, at ændringen i titlen er efter dit ønske og godkendt til trykning."
        },
        validation_popup: {
            confirm: "Bekræft",
            cancel: "Annuller",
            heading: "Gennemse din karakter",
            confirm_message: "Er du tilfreds med dette billede som grundlag for din personlige bog?",
            warning_title: "Vigtigt:",
            warning:
                "Husk: Når dit eventyr begynder, kan magien ikke ændres. Alle illustrationer vil være baseret på dette fortryllende billede.",
            original: "Originalt billede",
            mebook: "Minbørnebog billede"
        },
        edit_text_validation: {
            text_is_too_big: "Teksten er for stor",
            errors_of_pages: "Der er fejl på sider",
            errors_of_page: "Der er fejl på siden"
        },
        queue_text: "Din placering i køen:",
        title_editor_text: {
            f_size: "Skriftstørrelse",
            f_color: "Skriftfarve",
            hex_color: "HEX kode",
            preview: "Forhåndsvisning",
            save: "Gem og fortsæt",
            goback: "Tilbage til redigering",
            preview_mode: "Du er i forhåndsvisningstilstand",
            edit_text: "Rediger forside",
            reset: "Nulstil",
            text_is_too_big: "Teksten er for stor"
        },
        consent_popup: {
            title: "Gennemse venligst dine billeder",
            warning: "Bemærk venligst, at når bogen er produceret, kan der ikke foretages ændringer",
            buttons: {
                back: "Tilbage til redigering",
                continue: "Accepter og fortsæt"
            },
            consent_box: {
                title: "Mulige fejlformateringer",
                consent: "Jeg er enig, jeg har tjekket alle billeder korrekt, og der er ingen fejlformateringer"
            },
            instructions: (name) => [
                {
                    title: (
                        <p>
                            Sørg for, at barnets navn er <strong>{name}</strong>, og at der ikke er stavefejl i
                            historien, hvis du har foretaget ændringer.
                        </p>
                    )
                },
                {
                    title: <p>Sørg også for, at de markerede billeder er dem, du bedst kunne lide af de mulige fire.</p>
                }
            ]
        },
        general: {
            next: "Næste",
            pageTitle: "Bogoprettelse",
            try_again: "Prøv igen"
        },
        preebook_title: "Dine bøger",
        preebook_subTitle: "Bøger nedenfor er ikke i kurven",
        otp: {
            title: "Log venligst ind med dit telefonnummer",
            title_email: "Share your email",
            title_2: "SMS med koden er på vej",
            title_2_email: "Email with the code is on the way",
            code_is_invalid: "Koden er ugyldig",
            change_phone_number: "Skift telefonnummer",
            send_again: "Send igen"
        },
        fields: {
            name_male: "Barnets navn",
            name_female: "Barnets navn",
            age_hero: "Alder",
            gender: {
                male: "Dreng",
                female: "Pige"
            },
            age: "Alder:",
            apply: "Gem",
            email: "Email",
            phone: "Telefonnummer",
            email_validation: "Forkert email-format",
            sex: "Køn",
            age_validation: "Alder skal være"
        }
    };
};

export default danish;
